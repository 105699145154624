@font-face {
    font-family: 'Inika';
    src: url('Inika-Bold.woff2') format('woff2'),
        url('Inika-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-Regular.woff2') format('woff2'),
        url('Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-Medium.woff2') format('woff2'),
        url('Inter-Medium.woff') format('woff'); /* Medium font used as Semibold because it's more accurate to Figma mockup */
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Inika';
    src: url('Inika.woff2') format('woff2'),
        url('Inika.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

