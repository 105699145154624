@import "css/variables/mediaqueries.scss";

.about {

    p {
        margin: 0;
    }

    .about-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--spacing-3x);
    }

    @media (max-width: $window-small) {
        .about-list {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
