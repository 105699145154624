@import "css/variables/mediaqueries.scss";

@mixin menuBackground() {
    background-color: var(--color-secondary-darker-800);
    background-image: linear-gradient(180deg, var(--color-transparent-black-00) 0%, var(--color-transparent-black-20) 100%);
    box-shadow: var(--elevation-1x-t50);
}

.menu {
    position: fixed;
    z-index: 10;
    width: 100%;
    transition: background .3s, box-shadow .3s;

    .wrapper {
        display: flex;
        align-items: center;
        gap: 120px;
        padding-top: var(--spacing-3x);
        transition: padding-top .3s;

        .logo {

            a {
                display: inline-block;
                width: 166px;
                transition: width .3s;
            }

        }

        .mobile-menu {
            display: none;
        }

        nav {
            position: relative;
            top: 5px;
            transition: top .3s;
    
            ul {
                display: flex;
                gap: var(--spacing-6x);
                margin: 0;
                padding: 0;
                
                li {
                    list-style-type: none;
                    transition: margin-top .3s;
                    text-align: center;

                    a {
                        transition: font-size 0.3s, color .3s, top .3s, height .3s;
                    }
                }

            }
    

        }


    }

    &.scrolled {
        @include menuBackground();
        
        .wrapper {
            padding-block: 14px;
        }

    }

    &.scrolled,
    &.opened {

        .wrapper {
            gap: 80px;
            align-items: center;

            .logo {
                
                a {
                    width: 120px;
                }

            }

            nav {
                top: 0;

                li {
                    
                    a {
                        font-size: var(--font-size-medium);
                    }

                }

            }

        }
    }

    @media (max-width: $window-large) {
        .wrapper {
            gap: var(--spacing-8x);            

            nav {

                ul {
                    gap: var(--spacing-4x);
                }

            }

        }
    }

    @media (max-width: $window-medium) {
        .wrapper {
            justify-content: space-between;
            row-gap: 0;
            flex-wrap: wrap;
            padding-block: 8px;

            .logo {

                a {
                    width: 104px;
                    
                }

            }
            
            .mobile-menu {
                display: initial;
            }

            nav {
                display: grid;
                grid-template-rows: 0fr;
                top: 0;
                width: 100%;
                transition: grid-template-rows .3s, padding .3s;

                ul {
                    flex-direction: column;
                    width: 100%;
                    align-items: center;
                    gap: var(--spacing-2x);
                    overflow: hidden;

                    li {
                        margin-top: 0;
                        width: auto;
                    }
                }
            }
        }

        &.opened {
            @include menuBackground();
        }

        &.scrolled,
        &.opened {
    
            .wrapper {
                gap: 0;
                padding-block: 8px;
    
                .logo {
                    
                    a {
                        width: 104px;
                        
                    }
    
                }

            }
        }

        &.opened {
            
            .wrapper {

                nav {
                    padding-block: 16px 8px;
                    grid-template-rows: 1fr;
                }
            
            }

        }

    }

    @media (max-width: 290px) {
        .wrapper {
            gap: var(--spacing-1x);
            flex-direction: column;
            flex-wrap: nowrap;

            .logo {
                margin-left: 16px;
            }
        }
    }
}


