@import "css/variables/mediaqueries.scss";

@mixin posterWidth ($numberOfPosters) {
    @if ($numberOfPosters >= 8) {
        width: calc((100% - (($numberOfPosters - 1) * var(--spacing-3x))) / $numberOfPosters);
    } @else {
        width: calc((100% - (($numberOfPosters - 1) * var(--spacing-2x))) / $numberOfPosters);
    }
}

.poster-list {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-3x);

    .wrapper {
        @include posterWidth(8);
        flex-grow: 0;
        flex-shrink: 0;
        position: relative;

        .footer {
            display: none;
        }

        &.show-footer {
            &:hover {
                &> a {
                    position: relative;
                    
                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 35%;
                        background: linear-gradient(0deg, var(--color-transparent-black-80) 0%, var(--color-transparent-black-00) 100%);
                    }
    
                }
    
                .footer {
                    display: flex;
                    justify-content: flex-end;
                    position: absolute;
                    right: var(--spacing-1x);
                    bottom: var(--spacing-1x);
                }
    
            }

        }

    }

}

@media (max-width: $window-large) {
    .poster-list {
        gap: var(--spacing-2x);

        .wrapper {
            @include posterWidth(6);
        }

    }
}

@media (max-width: $window-medium) {
    .poster-list {
        
        .wrapper {
            @include posterWidth(4);
        }

    }
}

@media (max-width: $window-small) {
    .poster-list {

        .wrapper {
            @include posterWidth(3);
        }

    }
}