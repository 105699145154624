.super-message {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-6x);

    .info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .icon {
            text-align: center;
            font-size: 120px;
            color: var(--color-secondary-darker-300);
            line-height: 1;
            margin-bottom: -16px;
        }

        h2 {
            text-align: center;
            color: var(--color-secondary-darker-300);
        }
    }

    .message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: var(--spacing-1x);

        h2,
        p {
            text-align: center;
        }

        h2 {
            word-break: break-word;
        }

        a {
            color: var(--color-primary-lighter-500);
        }

    }

}