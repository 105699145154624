.input {
    position: relative;

    &::after {
        content: "search";
        display: grid;
        place-items: center;
        position: absolute;
        top: 0;
        left: var(--spacing-2x);
        height: 100%;
        font-family: "Material Symbols Rounded";
        font-size: 32px;
        color: var(--color-secondary-darker-600);
        font-variation-settings:
        'FILL' var(--icon-fill-outlined),
        'wght' 300,
        'GRAD' 0,
        'opsz' 24;
        user-select: none;
    }

    &:has(input:focus) {

        &::after {
            color: var(--color-primary);
        }

    }

    
    &:has(:not(:focus):not(:placeholder-shown)) {
        
        &::after {
            color: var(--color-white);
        }

    }

    input {
        width: 100%;
        height: 48px;
        margin: 0;
        padding:  0 0 0 var(--spacing-8x);
        background-color: var(--color-transparent);
        border: 2px solid var(--color-secondary-darker-600);
        border-radius: var(--rounded-corners-1x);
        outline: 0;
        font-family: var(--font-family-primary);
        font-size: var(--font-size-extra-large);
        font-weight: var(--font-weight-regular);
        color: var(--color-secondary-darker-400);
        box-sizing: border-box;


        &::placeholder {
            color: var(--color-secondary-darker-400);
        }

        &:focus {
            border-color: var(--color-primary);
            color: var(--color-primary);

            &::placeholder {
                color: var(--color-primary-darker-600);
            }
            
        }

        &:not(:focus):not(:placeholder-shown) {
            color: var(--color-white);
            border-color: var(--color-white);
        }

    }

}