@import "css/variables/mediaqueries.scss";

.banner {
    display: flex;
    justify-content: center;
    position: relative;
    height: 550px;
    user-select: none;

    .caroussel {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        $transitionTime: .5s;
        $transitionDelay: .5s;

        .container {
            position: absolute;
            height: 100%;
            width: 100%;
            
            .background {
                height: 100%;
                position: relative;
                
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: absolute;
                    opacity: 0;
                    transition: opacity .5s;

                    &.background-active {
                        opacity: 1;
                    }
                }

            }

        }

        .info-container {
            display: flex;
            align-items: flex-end;
            color: var(--color-white);
            overflow: hidden;

            .movie-info,
            .featured-image {
                position: absolute;
                height: 80%;
                width: 40%;
            }

            .movie-info {
                display: flex;
                flex-direction: column;
                gap: var(--spacing-8x);
                left: -45%;
                padding-left: 104px;;
                padding-top: var(--spacing-4x);
                box-sizing: border-box;
                transition: left $transitionTime ease-in;
                
                .movie-logo {
                    height: 48%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center left;
                    }
                }

                p {
                    margin: 0;
                }

                &.movie-info-active {
                    left: 0;
                    transition: left $transitionTime ease-out $transitionDelay;
                }
            }

            .featured-image {
                right: -45%;
                height: 80%;
                transition: right $transitionTime ease-in;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: bottom center;
                }

                &.featured-image-active {
                    right: 0;
                    transition: right $transitionTime ease-out $transitionDelay;
                }
            }
        }
    }

    .shadows {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image:
            linear-gradient(180deg, var(--color-transparent-black-60) 0%, var(--color-transparent-black-00) 100%),
            linear-gradient(67deg, var(--color-transparent-black-80) 0%, var(--color-transparent-black-00) 70%),;
        background-size: 100% 300px, 70% 100%;
        background-repeat: no-repeat;
    }

    .pagination {
        position: absolute;
        bottom: 0;
        padding-bottom: var(--spacing-1x);
    }

}

@media (max-width: $window-extra-large) {
    .banner {

        .caroussel {

            .info-container {

                .movie-info {
                    gap: var(--spacing-6x);
                    width: 45%;
                    left: -50%;

                    .movie-logo {
                        height: 35%;
                    }
                }

            }

        }

    }

}

@media (max-width: $window-medium) {
    .banner {
        height: 490px;

        .shadows {
            background-size: 100%, 0;
        }

        .caroussel {
            
            .info-container {
                display: flex;
                align-items: flex-end;
                color: var(--color-white);
                overflow: hidden;

                .movie-info,
                .featured-image {
                    width: 100%;
                }

                .movie-info {
                    gap: var(--spacing-3x);
                    padding-inline: var(--spacing-4x);
                    padding-top: var(--spacing-2x);
                    left: -105%;

                    .movie-logo {
                        height: 84px;

                        img {
                            object-position: center;
                        }
                    }
                }
    
                .featured-image {
                    right: -105%;
                    height: 40%;
                }
            }

        }
    }
}

@media (max-width: $window-small) {
    .banner {

        .caroussel {
            
            .info-container {
    
                .featured-image {
                    height: 35%;
                }

            }

        }

    }
}