@import "css/variables/mediaqueries.scss";

@mixin posterWidth ($numberOfPosters) {
    @if ($numberOfPosters >= 8) {
        width: calc((100% - (($numberOfPosters - 1) * var(--spacing-3x))) / $numberOfPosters);
    } @else {
        width: calc((100% - (($numberOfPosters - 1) * var(--spacing-2x))) / $numberOfPosters);
    }
}

.category-slider {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2x);
    position: relative;
    overflow: hidden;

    .container {
        display: flex;
        gap: var(--spacing-3x);
        position: relative;
        
        .poster-wrapper {
            display: block;
            @include posterWidth(8);
            flex-grow: 0;
            flex-shrink: 0;
            line-height: 0;
        }

        &[data-current-page="first"] ~  .slide-backward {
           display: none;
        }

        &[data-current-page="last"] ~  .slide-forward {
            display: none;
        }
    }

    .slide-forward, 
    .slide-backward {
        position: absolute;
        top: calc(50% + 24px);
        background-color: var(--color-transparent-black-60);
        border-radius: var(--rounded-corners-1x);
        scale: 0;
        opacity: 0;
        translate: 0 -50%;
        transition: opacity .5s;
    }

    $slideButtonsPosition: var(--spacing-1x);

    .slide-forward {
        right: $slideButtonsPosition;
    }

    .slide-backward {
        left: $slideButtonsPosition;
    }

    &:hover {

        .slide-forward, 
        .slide-backward {
            display: initial;
            scale: 1;
            opacity: 1;
        }

    }

}

@media (max-width: $window-large) {
    .category-slider {

        .container {
            gap: var(--spacing-2x);

            .poster-wrapper {
                @include posterWidth(6);
            }

        }

    }

}

@media (max-width: $window-medium) {
    .category-slider {
        gap: var(--spacing-1x);
        
        .container {
    
            .poster-wrapper {
                @include posterWidth(4);
            }
    
        }
        
    }

}

@media (max-width: $window-small) {
    .category-slider {
     
        .container {
    
            .poster-wrapper {
                @include posterWidth(3);
            }
    
        }
        
    }

}

@media only screen and (hover: none) and (pointer: coarse){
    .category-slider {
    
        .slide-forward, 
        .slide-backward {
            scale: 1;
            opacity: 1;
        }

    }

}