@font-face {
    font-family: 'Material Symbols Rounded';
    src: url('MaterialSymbolsRounded.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

.material-symbols-rounded {
    display: inline-block;
    font-family: "Material Symbols Rounded";
    user-select: none;

    &.icon-outlined-light {
        font-variation-settings:
        'FILL' var(--icon-fill-outlined),
        'wght' 200, // 300 in Figma
        'GRAD' 0,
        'opsz' 24;
    }

    &.icon-outlined-regular {
        font-variation-settings:
        'FILL' var(--icon-fill-outlined),
        'wght' 300, // 400 in Figma
        'GRAD' 0,
        'opsz' 24;
    }
    
    &.icon-outlined-semibold {
        font-variation-settings:
        'FILL' var(--icon-fill-outlined),
        'wght' 400, // 600 in Figma
        'GRAD' 0,
        'opsz' 24;
    }
    
    &.icon-filled-light {
        font-variation-settings:
        'FILL' var(--icon-fill-filled),
        'wght' 200, // 300 in Figma
        'GRAD' 0,
        'opsz' 24;
    }

    &.icon-filled-regular {
        font-variation-settings:
        'FILL' var(--icon-fill-filled),
        'wght' 300, // 400 in Figma
        'GRAD' 0,
        'opsz' 24;
    }

    &.icon-filled-semibold {
        font-variation-settings:
        'FILL' var(--icon-fill-filled),
        'wght' 400, // 600 in Figma
        'GRAD' 0,
        'opsz' 24;
    }

}

