:root {
    // Font Family
    --font-family-primary: "Inika";
    --font-family-secondary: "Inter";

    // Font Weight
    --font-weight-regular: normal;
    --font-weight-semibold: 600;
    --font-weight-bold: bold;

    // Font Size
    --font-size-micro: 11px;
    --font-size-mini: 12px;
    --font-size-small: 14px;
    --font-size-medium: 16px;
    --font-size-large: 18px;
    --font-size-extra-large: 20px;
    --font-size-giant: 24px;
    --font-size-extra-giant: 28px;
    --font-size-titan: 32px;
    --font-size-extra-titan: 40px;
    --font-size-ultra: 48px;

    // Line Height
    --line-height-small: 14px;
    --line-height-medium: 16px;
    --line-height-large: 18px;
    --line-height-extra-large: 20px;
    --line-height-giant: 24px;
    --line-height-extra-giant: 28px;
    --line-height-titan: 32px;
    --line-height-extra-titan: 40px;
    --line-height-ultra: 48px;

}