.slider {
    position: relative;
    cursor: pointer;
    user-select: none;

    .background {
        height: 6px;
        border-radius: var(--rounded-corners-full);
        background-color: var(--color-transparent-white-20);
        overflow: hidden;

        .current-position {
            width: 0;
            height: 100%;
            background-color: var(--color-white);
        }

    }

    .handle {
        position: absolute;
        top: 50%;
        height: 20px;
        width: 20px;
        background: white;
        border-radius: var(--rounded-corners-full);
        transform: translate(-50%, -50%);
        box-shadow: var(--elevation-1x-t50);

        &.video-handle-time {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            
            &::before {
                content: attr(data-videohandletime);
                display: inline-block;
                position: relative;
                top: -30px;
                padding: var(--spacing-0-5x) var(--spacing-1x);
                border-radius: var(--rounded-corners-0-5x);
                background: var(--color-white);
                box-shadow: var(--elevation-1x-t50);
                font-size: var(--font-size-small);
                line-height: var(--line-height-small);
                font-weight: var(--font-weight-semibold);
                color: var(--color-secondary-darker-700);
            }
        }
    }
}