:root {
    --spacing-0x: 0;
    --spacing-0-25x: 2px;
    --spacing-0-5x: 4px;
    --spacing-1x: 8px;
    --spacing-2x: 16px;
    --spacing-3x: 24px;
    --spacing-4x: 32px;
    --spacing-5x: 40px;
    --spacing-6x: 48px;
    --spacing-7x: 56px;
    --spacing-8x: 64px;
}