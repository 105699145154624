.not-found {
    position: absolute;
    display: grid;
    place-items: center;

    .background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url(./notfound-background.jpg) no-repeat center;
        background-size: cover;
        opacity: .3;
        
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            backdrop-filter: grayscale(100%);
        }

    }

    .outer-content {
        z-index: 1;
    }

}