.poster {
    word-break: break-word;
    
    img {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        aspect-ratio: 179/254;
        background-color: var(--color-secondary-darker-800);
        object-fit: cover;

        &:-moz-loading {
            visibility: hidden;
        }

        &::before,
        &::after {
            display: grid;
            place-items: end center;
            position: absolute;
            top: 0;
            left: 0;
            box-sizing: border-box;
            width: 100%;
            height: 50%;
            padding: var(--spacing-1x);
            background-color: var(--color-secondary-darker-800);
            color: var(--color-secondary-darker-400);
            font-size: var(--font-size-small);
            line-height: 1.1;
            text-align: center;
        }

        &::before {
            content: "movie";
            padding-bottom: 0;
            font-family: "Material Symbols Rounded";
            font-size: 40px;
        }

        &::after {
            content: attr(alt);
            place-items: start center;
            padding-top: 0;
            top: auto;
            bottom: 0;
        }
    }
}

@media (max-width: 320px) {
    .poster {
    
        img {

            &::before {
                font-size: 8vw;
            }
    
            &::after {
                font-size: var(--font-size-mini);
            }
    
        }
    }
}

// Exceptions for Firefox
@-moz-document url-prefix() {
    .poster img {
            
        &::before {
            display: none;
        }

        &::after {
            place-items: center;
            height: 100%;
        }

    }

}