.play {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--color-black);
    overflow: hidden;

    &.hide-cursor:hover {
        cursor: none;
    }

    .video-container {
        position: absolute;
        width: 100%;
        height: 100%;

        .glass {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: absolute;
            width: 100%;
            height: 100%;

            &::before,
            &::after {
                content: "";
                display: block;
                position: relative;
                width: 100%;
                height: 38%;
            }

            &::before {
                background-image: linear-gradient(180deg, var(--color-transparent-black-80) 0%, var(--color-transparent-black-00) 100%);
            }

            &::after {
                background-image: linear-gradient(0deg, var(--color-transparent-black-80) 0%, var(--color-transparent-black-00) 100%);
            }
        }

        .ad {
            position: absolute;
            z-index: 10;
            width: 100%;
            height: 100%;
            background-color: var(--color-secondary-darker-900);

            .ad-container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                overflow: auto;

                .ad-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    z-index: 20;
                    padding: var(--spacing-4x);


                    .ad-skip {

                        .ad-skip-message {
                            user-select: none;;
                        }

                        .ad-skip-button {
                            display: none;
                        }
                        
                        &.show-ad-skip-button {

                            .ad-skip-message {
                                display: none;
                            }
                            
                            .ad-skip-button {
                                display: initial;
                            }
    
                        }

                    }

                }
            }

        }

        video {
            position: absolute;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            order: 1;
        }
    }

    .actions .actions-wrapper,
    .controls .controls-wrapper {
        position: relative;
        top: 0;
        transition: top .5s;
    }

    .actions {
        align-self: flex-start;
        padding: var(--spacing-4x) 0 0 var(--spacing-4x);

        .actions-wrapper {

            &.hide-actions {
                top: calc(-100% - var(--spacing-4x));
            }
        }
        
    }

    .controls {
        padding: 0 var(--spacing-4x) var(--spacing-4x);

        .controls-wrapper {
            display: none;
            width: 100%;
            
            &.show-controls {
                display: block;
            }
    
            &.hide-controls {
                top: calc(100% + var(--spacing-5x));
            }
        }

    }

    .loading-warning {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-1x);
        
        &.loading-warning-hide {
            display: none;
        }

        .loading-icon {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
            
            div {
                box-sizing: border-box;
                display: block;
                position: absolute;
                width: 64px;
                height: 64px;
                margin: 6px;
                border: 6px solid var(--color-white);
                border-radius: 50%;
                animation: loading-icon 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                border-color: var(--color-white) transparent transparent transparent;
                
                &:nth-child(1) {
                    animation-delay: -0.45s;
                }
            
                &:nth-child(2) {
                    animation-delay: -0.3s;
                }
            
                &:nth-child(3) {
                    animation-delay: -0.15s;
                }
            }
            
        }

    }

}

.ad-content {
    position: absolute;
    top: 50%;
    left: 50%;
    background: var(--color-transparent-black-30) url(./logo.svg) no-repeat center;
    translate: -50% -50%;
    z-index: 20;
}

.ad-hide {
    display: none;
}

@keyframes loading-icon {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}