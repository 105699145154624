.display {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0 var(--spacing-3x);
    border-radius: var(--rounded-corners-1x);
    background-color: var(--color-secondary-darker-700);
    text-decoration: none;

    &.display-subtitle {
        height: 104px;
    }

    &.display-icon {
        padding: 0 var(--spacing-3x) 0 var(--spacing-2x);
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-1x);
        
        .title-content {
            display: flex;
            align-items: center;
            gap: var(--spacing-2x);
            color: var(--color-white);

            .icon {
                display: block;
                font-size: 40px;
                line-height: 40px;
                width: 40px;
                height: 40px;
                overflow: hidden;
            }

            .title {
                font-family: var(--font-family-primary);
                font-size: var(--font-size-extra-large);
            }
        
        }
    
        .subtitle {
            font-family: var(--font-family-secondary);
            font-size: var(--font-size-small);
            line-height: var(--line-height-extra-large);
            color: var(--color-secondary-darker-400);
        }
    }

}