@import "css/variables/mediaqueries.scss";

.categories-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--spacing-3x);
}

@media (max-width: $window-extra-large) {
    .categories-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: $window-large) {
    .categories-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: $window-medium) {
    .categories-list {
        grid-template-columns: repeat(1, 1fr);
    }
}