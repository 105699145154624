@import "css/variables/mediaqueries.scss";

body {
    margin: 0;
    background-color: var(--color-secondary-darker-900);
    background-image: radial-gradient(var(--color-transparent-white-30) 0%, var(--color-transparent-white-00) 65%);
    background-size: 2590px 2590px;
    background-repeat: no-repeat;
    background-position: center -2040px;
    font-family: var(--font-family-secondary);
    color: var(--color-white);
    font-size: var(--font-size-medium);
    line-height: var(--line-height-extra-large);
}

.outer-content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-5x);
    padding: var(--spacing-5x) 0 var(--spacing-8x);
}

.outer-content-full {
    box-sizing: border-box;
    min-height: 100%;
    width: 100%;
}

.outer-content-common {
    padding: 124px 0 var(--spacing-8x);
}

.content {
    padding: 0 var(--spacing-8x);
}

.content-page {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-5x);
    padding: var(--spacing-3x) var(--spacing-8x) 0;
}

.no-margin {
    margin: 0;
}

.screen-blocker-error > :not([class^="Menu"]):not([class^="ScreenBlocker"]) {
    display: none
}

@media (max-width: $window-medium) {
    .content {
        padding: 0 var(--spacing-3x);
    }
}