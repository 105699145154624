:root {

    // Primary
    --color-primary-lighter-900: #f8e6ff;
    --color-primary-lighter-800: #f2ccff;
    --color-primary-lighter-700: #ebb3ff;
    --color-primary-lighter-600: #e599ff;
    --color-primary-lighter-500: #de80ff;
    --color-primary-lighter-400: #d766ff;
    --color-primary-lighter-300: #d14dff;
    --color-primary-lighter-200: #ca33ff;
    --color-primary-lighter-100: #c41aff;
    --color-primary: #bd00ff;
    --color-primary-darker-100: #aa00e6;
    --color-primary-darker-200: #9700cc;
    --color-primary-darker-300: #8400b3;
    --color-primary-darker-400: #710099;
    --color-primary-darker-500: #5f0080;
    --color-primary-darker-600: #4c0066;
    --color-primary-darker-700: #39004d;
    --color-primary-darker-800: #260033;
    --color-primary-darker-900: #13001a;

    // Secondary
    --color-secondary-lighter-900: #f1f2fa;
    --color-secondary-lighter-800: #e3e5f5;
    --color-secondary-lighter-700: #d5d7ef;
    --color-secondary-lighter-600: #c7caea;
    --color-secondary-lighter-500: #babde5;
    --color-secondary-lighter-400: #acb0e0;
    --color-secondary-lighter-300: #9ea3db;
    --color-secondary-lighter-200: #9095d5;
    --color-secondary-lighter-100: #8288d0;
    --color-secondary: #747bcb;
    --color-secondary-darker-100: #747bcb;
    --color-secondary-darker-200: #686fb7;
    --color-secondary-darker-300: #5d62a2;
    --color-secondary-darker-400: #464a7a;
    --color-secondary-darker-500: #3a3e66;
    --color-secondary-darker-600: #2e3151;
    --color-secondary-darker-700: #23253d;
    --color-secondary-darker-800: #171929;
    --color-secondary-darker-900: #0c0c14;

    // Neutrals
    --color-white: #ffffff;
    --color-black: #000000;

    // Transparency
    --color-transparent: transparent;

    // Transparency White
    --color-transparent-white-00: #ffffff00;
    --color-transparent-white-10: #ffffff1a;
    --color-transparent-white-20: #ffffff33;
    --color-transparent-white-30: #ffffff4d;
    --color-transparent-white-40: #ffffff66;
    --color-transparent-white-50: #ffffff80;
    --color-transparent-white-60: #ffffff99;
    --color-transparent-white-70: #ffffff99;
    --color-transparent-white-80: #ffffffcc;
    --color-transparent-white-90: #ffffffe6;

    // Transparency Black
    --color-transparent-black-00: #00000000;
    --color-transparent-black-10: #0000001a;
    --color-transparent-black-20: #00000033;
    --color-transparent-black-30: #0000004d;
    --color-transparent-black-40: #00000066;
    --color-transparent-black-50: #00000080;
    --color-transparent-black-60: #00000099;
    --color-transparent-black-70: #000000b3;
    --color-transparent-black-80: #000000cc;
    --color-transparent-black-90: #000000e6;
}