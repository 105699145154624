@import "css/variables/mediaqueries.scss";

.menu-item {
    display: inline-flex;
    align-items: center;
    position: relative;
    height: 100%;
    top: 0;
    font-family: var(--font-family-primary);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-extra-large);
    color: var(--color-white);
    text-decoration: none;
    transition: color .3s, height .3s, top .3s;
    user-select: none;

    &:hover {
        height: 135%;
        top: -35%;
        color: var(--color-primary);
    }
}

@media (max-width: $window-large) {
    .menu-item {
        font-size: var(--font-size-medium);
    }
}