:root {
    --rounded-corners-0x: 0;
    --rounded-corners-0-25x: 2px;
    --rounded-corners-0-5x: 4px;
    --rounded-corners-1x: 8px;
    --rounded-corners-2x: 16px;
    --rounded-corners-3x: 24px;
    --rounded-corners-4x: 32px;
    --rounded-corners-5x: 40px;
    --rounded-corners-6x: 48px;
    --rounded-corners-7x: 56px;
    --rounded-corners-8x: 64px;
    --rounded-corners-full: 999999px;
}